import React from 'react'
import PropTypes from 'prop-types'
import {
  compose,
  withState,
  withHandlers,
  withProps,
  setDisplayName,
} from 'recompose'
import get from 'lodash.get'

import { filterProps } from '@jsluna/utils'

import TableContainer from './TableContainer'
import TableCaption from './TableCaption'
import TableHeader from './TableHeader'
import TableHeaderRow from './TableHeaderRow'
import TableHeaderCell from './TableHeaderCell'
import TableBody from './TableBody'
import TableRow from './TableRow'
import TableCell from './TableCell'

import { sort, sortDataHandler } from './sort'

const DEFAULT_CELL_DATA_PROPERTY = 'value'

const buildAccessor = accessor => {
  if (typeof accessor === 'function') {
    return accessor
  }

  if (typeof accessor === 'string') {
    return row => ({ [DEFAULT_CELL_DATA_PROPERTY]: row[accessor] })
  }

  return row => row
}

const buildAccessors = columns =>
  columns.reduce((acc, curr) => {
    acc[curr.name] = buildAccessor(curr.accessor)
    return acc
  }, {})

const buildColClassName = columns =>
  columns.reduce(
    (classNames, column) => ({
      ...classNames,
      [column.name]:
        typeof column.className === 'object'
          ? column.className
          : { th: column.className, td: column.className },
    }),
    {},
  )

/**
 * The `column` prop definition can be customised with the following options:
 *
 * - `renderHead`: `node` - what to display in the column heading, by default column name is displayed
 * - `accessor`: `func` - access deeply nested data structures or apply transformations to data should follow the format `data => ({ value: data.email.toLowerCase() })`
 * - `render`: `node` - determine how the cell contents are output, receives props defined by the `accessor` function so may contain multiple pieces of row data e.g. `props => <span><b>{props.label}</b> {props.unit}</span>`
 * - `sort`: `string` or `func` - String value that determines what value returned by the `accessor` has alphabetical sorting applied to it, alternatively passing a function allows for custom sorting (`accessor`: `func`, `ascending`: bool) => [sort function](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort)
 * - `sortable`: `bool` - determines whether this column is sortable or not. Overrides top level `<Table />` prop `sortable`
 * - `className`: `string` or `obj` - adds a custom class to the cells within that column, can provide an object (`{ th: 'header', td: 'body' }`) to have different classes for header & body cells.
 *
 */
const Table = ({
  columns,
  data,
  className,
  responsive,
  labels,
  sortable,
  sortData,
  sorted,
  caption,
  visuallyHiddenCaption,
  fixed,
  rowKey,
  ...rest
}) => {
  const accessors = buildAccessors(columns)
  const colClassNames = buildColClassName(columns)
  const isSortable = sortable || columns.filter(c => c.sortable).length > 0

  return (
    <TableContainer
      className={className}
      responsive={responsive}
      labels={labels}
      sortable={sortable}
      {...filterProps(rest, ['setSorted'])}
    >
      {caption && (
        <TableCaption visuallyHidden={visuallyHiddenCaption}>
          {caption}
        </TableCaption>
      )}
      <TableHeader sortLabel={responsive && isSortable}>
        <TableHeaderRow>
          {columns.map(column => {
            let sortDirection
            if (sorted.column === column.name) {
              sortDirection = sorted.ascending ? 'ascending' : 'descending'
            }

            return (
              <TableHeaderCell
                key={column.name}
                sortDirection={sortDirection}
                onSort={
                  column.sortable || (column.sortable === undefined && sortable)
                    ? ascendingActive =>
                        sortData({
                          column: column.name,
                          accessor: accessors[column.name],
                          ascending: !ascendingActive,
                          sortValue: column.sort || DEFAULT_CELL_DATA_PROPERTY,
                        })
                    : undefined
                }
                align={column.align}
                className={colClassNames[column.name].th}
              >
                {column.renderHead === undefined
                  ? column.name
                  : column.renderHead}
              </TableHeaderCell>
            )
          })}
        </TableHeaderRow>
      </TableHeader>
      <TableBody>
        {data.map((row, index) => (
          <TableRow key={get(row, rowKey, index)}>
            {columns.map(column => {
              const cellData = accessors[column.name](row)
              return (
                <TableCell
                  key={column.name}
                  align={column.align}
                  className={colClassNames[column.name].td}
                  label={
                    responsive && labels && !column.hideLabel
                      ? column.name
                      : undefined
                  }
                  lastWhenStacked={responsive && column.lastWhenStacked}
                >
                  {column.render
                    ? column.render(cellData)
                    : cellData[DEFAULT_CELL_DATA_PROPERTY]}
                </TableCell>
              )
            })}
          </TableRow>
        ))}
      </TableBody>
    </TableContainer>
  )
}

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      renderHead: PropTypes.node,
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      sort: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      sortable: PropTypes.bool,
      render: PropTypes.func,
      /** Displays this cell at the bottom when stack view presented - requires top-level `responsive` prop to be enabled */
      lastWhenStacked: PropTypes.bool,
    }),
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  responsive: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  labels: PropTypes.bool,
  sortable: PropTypes.bool,
  sortData: PropTypes.func,
  sorted: PropTypes.shape({
    column: PropTypes.string,
    property: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    ascending: PropTypes.bool,
  }),
  caption: PropTypes.node,
  visuallyHiddenCaption: PropTypes.bool,
  fixed: PropTypes.bool,
  /** This supports deep gets within the data, e.g. 'property.id' */
  rowKey: PropTypes.string,
}

Table.defaultProps = {
  className: undefined,
  responsive: false,
  labels: true,
  sortable: false,
  sortData: undefined,
  sorted: {},
  caption: undefined,
  visuallyHiddenCaption: false,
  fixed: false,
  rowKey: 'id',
}

Table.displayName = 'Table'

export { Table as TableComponent }
export default compose(
  setDisplayName('Table'),
  withState('sorted', 'setSorted', {}),
  withHandlers({
    sortData: sortDataHandler,
  }),
  withProps(sort),
)(Table)
