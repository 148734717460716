import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { FormGroup } from '@jsluna/form'

import InputGroup from './InputGroup'
import ResultList from './ResultList'
import HiddenInput from './HiddenInput'

import {
  getOptionLabel as getOptionLabelDefault,
  getOptionValue as getOptionValueDefault,
} from './getOptionProperties'

import createFilterDefault from './createFilter'

const AutocompleteFormGroup = ({
  name,
  required,
  optional,
  multiSelect,
  async,
  selectedOptions,
  onClear,
  placeholder,
  getOptionLabel,
  getOptionValue,
  icon,
  action,
  onChange,
  options,
  filter,
  value,
  optionRenderer,
  noResultsMessage,

  label,
  hideLabel,
  error,
  warning,
  info,
  disabled,
  className,
  validationFirst,
  element,
  spellcheck,

  getInputProps,
  getItemProps,
  getLabelProps,
  getMenuProps,
  isOpen,
  inputValue,
  highlightedIndex,
  selectedItem,
  getToggleButtonProps,
  clearSelection,
  ...rest
}) => (
  <FormGroup
    labelElement={
      // eslint-disable-next-line jsx-a11y/label-has-for,jsx-a11y/label-has-associated-control
      props => <label {...props} {...getLabelProps()} />
    }
    name={name}
    required={required}
    optional={optional}
    label={label}
    hideLabel={hideLabel}
    error={error}
    warning={warning}
    info={info}
    className={className}
    validationFirst={validationFirst}
    element={element}
  >
    {inputProps => (
      <Fragment>
        <HiddenInput
          name={name}
          multiSelect={multiSelect}
          value={
            multiSelect
              ? selectedOptions.map(option => getOptionValue(option))
              : getOptionValue(selectedItem)
          }
        />
        <InputGroup
          {...inputProps}
          getInputProps={getInputProps}
          getToggleButtonProps={getToggleButtonProps}
          getClearButtonProps={props => ({
            onClick: onClear || clearSelection,
            'aria-label': 'Clear the field',
            ...props,
          })}
          selectedOption={!multiSelect ? selectedItem : undefined}
          selectedOptions={selectedOptions}
          isOpen={isOpen}
          optional={optional}
          placeholder={placeholder}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          icon={icon}
          action={action}
          onChange={onChange}
          hideInput={!async && options.length === 0}
          disabled={disabled}
          spellCheck={spellcheck}
          {...rest}
        />
        <ResultList
          getMenuProps={getMenuProps}
          getItemProps={getItemProps}
          selectedItem={selectedItem}
          isOpen={isOpen}
          highlightedIndex={highlightedIndex}
          options={options.filter(filter(value || inputValue, getOptionLabel))}
          optionRenderer={optionRenderer}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          noResultsMessage={noResultsMessage}
        />
      </Fragment>
    )}
  </FormGroup>
)

AutocompleteFormGroup.propTypes = {
  ...FormGroup.propTypes,

  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  optionRenderer: PropTypes.func,
  hideLabel: PropTypes.bool,
  required: PropTypes.bool,
  optional: PropTypes.bool,
  info: PropTypes.node,
  disabled: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  filter: PropTypes.func,
  placeholder: PropTypes.string,
  icon: PropTypes.node,
  action: PropTypes.bool,
  multiSelect: PropTypes.bool,
  async: PropTypes.bool,
  value: PropTypes.string,
  selectedOptions: PropTypes.arrayOf(PropTypes.object),
  onClear: PropTypes.func,
  onSelect: PropTypes.func,
  spellcheck: PropTypes.bool,

  getInputProps: PropTypes.func.isRequired,
  getItemProps: PropTypes.func.isRequired,
  getLabelProps: PropTypes.func.isRequired,
  getMenuProps: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  inputValue: PropTypes.string,
  highlightedIndex: PropTypes.number,
  selectedItem: PropTypes.shape({}),
  getToggleButtonProps: PropTypes.func.isRequired,
  clearSelection: PropTypes.func.isRequired,
}

// eslint-disable-next-line react/forbid-foreign-prop-types
delete AutocompleteFormGroup.propTypes.children

AutocompleteFormGroup.defaultProps = {
  options: [],
  onChange: undefined,
  optionRenderer: undefined,
  hideLabel: false,
  required: undefined,
  optional: undefined,
  info: undefined,
  disabled: false,
  getOptionLabel: getOptionLabelDefault,
  getOptionValue: getOptionValueDefault,
  filter: createFilterDefault,
  placeholder: undefined,
  icon: undefined,
  action: undefined,
  multiSelect: false,
  async: false,
  value: undefined,
  selectedOptions: [],
  onClear: undefined,
  onSelect: undefined,

  isOpen: false,
  inputValue: undefined,
  highlightedIndex: undefined,
  selectedItem: undefined,
  spellcheck: false,
}

AutocompleteFormGroup.displayName = 'AutocompleteFormGroup'

export default AutocompleteFormGroup
